@font-face {
font-family: '__Haffer_ce15cd';
src: url(/_next/static/media/627742a449199132-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__Haffer_Fallback_ce15cd';src: local("Arial");ascent-override: 82.86%;descent-override: 17.42%;line-gap-override: 0.00%;size-adjust: 109.10%
}.__className_ce15cd {font-family: '__Haffer_ce15cd', '__Haffer_Fallback_ce15cd'
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* inter-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-cyrillic-ext-400-normal.06b6faa3.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.2ae8ed37.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* inter-cyrillic-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-cyrillic-400-normal.5122dff0.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.2ae8ed37.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* inter-greek-ext-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-greek-ext-400-normal.2271c2a1.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.2ae8ed37.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* inter-greek-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-greek-400-normal.d1deb2fe.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.2ae8ed37.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* inter-vietnamese-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-vietnamese-400-normal.ebde713a.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.2ae8ed37.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* inter-latin-ext-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-latin-ext-400-normal.261aa6d4.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.2ae8ed37.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* inter-latin-400-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/inter-latin-400-normal.493934f7.woff2) format('woff2'), url(/_next/static/media/inter-all-400-normal.2ae8ed37.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* inter-cyrillic-ext-500-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/inter-cyrillic-ext-500-normal.563e11f7.woff2) format('woff2'), url(/_next/static/media/inter-all-500-normal.56c3d87b.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* inter-cyrillic-500-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/inter-cyrillic-500-normal.017c512f.woff2) format('woff2'), url(/_next/static/media/inter-all-500-normal.56c3d87b.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* inter-greek-ext-500-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/inter-greek-ext-500-normal.9ef8ce0d.woff2) format('woff2'), url(/_next/static/media/inter-all-500-normal.56c3d87b.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* inter-greek-500-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/inter-greek-500-normal.599dd974.woff2) format('woff2'), url(/_next/static/media/inter-all-500-normal.56c3d87b.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* inter-vietnamese-500-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/inter-vietnamese-500-normal.7b524ed3.woff2) format('woff2'), url(/_next/static/media/inter-all-500-normal.56c3d87b.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* inter-latin-ext-500-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/inter-latin-ext-500-normal.47503116.woff2) format('woff2'), url(/_next/static/media/inter-all-500-normal.56c3d87b.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* inter-latin-500-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/inter-latin-500-normal.b7be75b9.woff2) format('woff2'), url(/_next/static/media/inter-all-500-normal.56c3d87b.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* inter-cyrillic-ext-600-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/_next/static/media/inter-cyrillic-ext-600-normal.4eb7a897.woff2) format('woff2'), url(/_next/static/media/inter-all-600-normal.d9666969.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* inter-cyrillic-600-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/_next/static/media/inter-cyrillic-600-normal.9f2082d5.woff2) format('woff2'), url(/_next/static/media/inter-all-600-normal.d9666969.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* inter-greek-ext-600-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/_next/static/media/inter-greek-ext-600-normal.04ef07b9.woff2) format('woff2'), url(/_next/static/media/inter-all-600-normal.d9666969.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* inter-greek-600-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/_next/static/media/inter-greek-600-normal.eff3b663.woff2) format('woff2'), url(/_next/static/media/inter-all-600-normal.d9666969.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* inter-vietnamese-600-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/_next/static/media/inter-vietnamese-600-normal.eb82de1d.woff2) format('woff2'), url(/_next/static/media/inter-all-600-normal.d9666969.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* inter-latin-ext-600-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/_next/static/media/inter-latin-ext-600-normal.0a04c3cd.woff2) format('woff2'), url(/_next/static/media/inter-all-600-normal.d9666969.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* inter-latin-600-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(/_next/static/media/inter-latin-600-normal.a3e93aa0.woff2) format('woff2'), url(/_next/static/media/inter-all-600-normal.d9666969.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* inter-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-cyrillic-ext-700-normal.7c668daa.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.f3163a49.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* inter-cyrillic-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-cyrillic-700-normal.8b03fbd1.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.f3163a49.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* inter-greek-ext-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-greek-ext-700-normal.5fb4f9ba.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.f3163a49.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* inter-greek-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-greek-700-normal.54b722f0.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.f3163a49.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* inter-vietnamese-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-vietnamese-700-normal.00e401a9.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.f3163a49.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* inter-latin-ext-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-latin-ext-700-normal.07491ea2.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.f3163a49.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* inter-latin-700-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/inter-latin-700-normal.7ddf3c11.woff2) format('woff2'), url(/_next/static/media/inter-all-700-normal.f3163a49.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* inter-cyrillic-ext-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/inter-cyrillic-ext-900-normal.b3ebc21f.woff2) format('woff2'), url(/_next/static/media/inter-all-900-normal.bca024d2.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* inter-cyrillic-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/inter-cyrillic-900-normal.52f0a05f.woff2) format('woff2'), url(/_next/static/media/inter-all-900-normal.bca024d2.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* inter-greek-ext-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/inter-greek-ext-900-normal.1f5a8d9e.woff2) format('woff2'), url(/_next/static/media/inter-all-900-normal.bca024d2.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* inter-greek-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/inter-greek-900-normal.5ca1f758.woff2) format('woff2'), url(/_next/static/media/inter-all-900-normal.bca024d2.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* inter-vietnamese-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/inter-vietnamese-900-normal.8d9d0e81.woff2) format('woff2'), url(/_next/static/media/inter-all-900-normal.bca024d2.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* inter-latin-ext-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/inter-latin-ext-900-normal.a8149aa0.woff2) format('woff2'), url(/_next/static/media/inter-all-900-normal.bca024d2.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* inter-latin-900-normal*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/inter-latin-900-normal.307c1a48.woff2) format('woff2'), url(/_next/static/media/inter-all-900-normal.bca024d2.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

